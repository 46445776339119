<template>
   <div dir="ltr" class="customize__index text-left DashboardMain" >
      <NavBar></NavBar>
      <NewLayout/>
    <!-- <router-view></router-view> -->
  </div>
</template>

<script>
import NavBar from "./components/nav.vue"
import NewLayout from "./Layout/index.vue";
import {mapActions} from "vuex";
export default {
components:{
      NavBar,
      NewLayout
  },

  async  mounted(){
        await this.loadNavbarData();
    },
methods:{
  ...mapActions(["loadNavbarData"]),
},
}
</script>

<style>

</style>